import { useQuery } from '@apollo/react-hooks';

import OriginTracing from '@atlassiansox/origin-tracing';

import { getAGGClient } from '@confluence/graphql';
import { isErrorMarkedAsHandled, markErrorAsHandled } from '@confluence/graphql-error-processor';
import { useSessionData } from '@confluence/session-data';

import { UpgradeUrlQuery } from '../graphql/UpgradeUrlQuery.agggraphql';

const TRIAL_START_URL_FALLBACK = '/admin/billing';

export const useUpgradeUrlQuery = ({ skip }: { skip: boolean }) => {
	const origin = new OriginTracing({ product: 'confluence' });
	const { cloudId } = useSessionData();

	const { data, error, loading } = useQuery(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		UpgradeUrlQuery,
		{
			client: getAGGClient(),
			variables: {
				cloudId,
				productKey: 'confluence.ondemand',
				offeringKey: '06ef245c-5fa3-473b-8822-5e6fe072ae4b',
			},
			skip: skip || !cloudId,
		},
	);

	if (!isErrorMarkedAsHandled(error)) {
		markErrorAsHandled(error);
	}

	const changeOfferingUrl =
		data?.tenantContexts[0]?.entitlementInfo?.entitlement?.experienceCapabilities?.changeOffering
			?.experienceUrl;

	const trialStartUrl = changeOfferingUrl || TRIAL_START_URL_FALLBACK;

	return {
		loading,
		UpgradeUrl: origin.addToUrl(trialStartUrl),
	};
};
