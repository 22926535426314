import React, { memo, useContext } from 'react';
import { useIntl } from 'react-intl-next';

import { Box, xcss } from '@atlaskit/primitives';

import AiOptIn from '@atlassian/ai-opt-in';

import { SPAViewContext } from '@confluence/spa-view-context';
import { useSessionData } from '@confluence/session-data';
import { ConfluenceEdition } from '@confluence/change-edition/entry-points/ConfluenceEdition';
import {
	useFireQuickSummaryExposureEvent,
	useQuickSummaryABExperiment,
	QuickAISummaryPopupContent,
} from '@confluence/experiment-quick-summary';

import { QuickSummaryStreamingComponent } from './QuickSummaryStreaming/QuickSummaryStreamingComponent';
import { QuickSummaryUpsell } from './QuickSummaryUpsell';
import { ATLASSIAN_INTELLIGENCE_LEARN_MORE_LINK } from './constants';
import { i18n } from './i18n';

const styles = xcss({ width: '500px' });
type QuickSummaryPopupContentProps = {
	contentId: string;
	spaceKey?: string;
	contentType: string;
	lastModifiedDate: string;
	source: string;
	contentPath: string;
	setIsOpen: (state: boolean) => void;
};

export const QuickSummaryPopupContent = memo(
	({
		contentId,
		contentType,
		spaceKey,
		lastModifiedDate,
		source,
		contentPath,
		setIsOpen,
	}: QuickSummaryPopupContentProps) => {
		const intl = useIntl();
		const { isSiteAdmin } = useContext(SPAViewContext);
		const { isAdminHubAIEnabled, edition, orgId } = useSessionData();

		const adminLink = `https://admin.atlassian.com/o/${orgId}/atlassian-intelligence`;

		const {
			isQualified: isQuickSummaryABExperimentQualified,
			isVariation: isQuickSummaryABExperimentVariation,
		} = useQuickSummaryABExperiment();

		const { fireQuickSummaryExposureEvent: fireQuickSummaryABExposureEvent } =
			useFireQuickSummaryExposureEvent({
				skip: !isQuickSummaryABExperimentQualified,
				experimentName: 'confluence_quick_summary',
			});

		if (isAdminHubAIEnabled) {
			return (
				<Box xcss={styles}>
					<QuickSummaryStreamingComponent
						contentId={contentId}
						spaceKey={spaceKey}
						lastModifiedDate={lastModifiedDate}
						source={source}
						contentPath={contentPath}
						contentType={contentType}
						setIsOpen={setIsOpen}
					/>
				</Box>
			);
		}

		if (edition !== ConfluenceEdition.PREMIUM) {
			if (isQuickSummaryABExperimentQualified) {
				fireQuickSummaryABExposureEvent();

				if (isQuickSummaryABExperimentVariation) {
					return <QuickAISummaryPopupContent />;
				}
			}
			return <QuickSummaryUpsell />;
		}

		const formatOptInMessageValues = {
			a: (s: React.ReactNode[]) => (
				<a href={ATLASSIAN_INTELLIGENCE_LEARN_MORE_LINK} target="_blank">
					{s}
				</a>
			),
			br: () => <br />,
		};

		return (
			<AiOptIn
				source="quickSummary"
				product="confluence"
				isOrgAdmin={isSiteAdmin}
				headerText={intl.formatMessage(i18n.quickSummaryOptInDialogHeaderText)}
				bodyText={intl.formatMessage(
					i18n.quickSummaryOptInDialogBodyText,
					formatOptInMessageValues,
				)}
				adminBodyText={intl.formatMessage(
					i18n.quickSummaryAdminOptInDialogBodyText,
					formatOptInMessageValues,
				)}
				adminLink={adminLink}
				onAdminClose={() => setIsOpen(false)}
			/>
		);
	},
);
