import React, { useEffect, useState } from 'react';
import { useIntl, defineMessages } from 'react-intl-next';

import { Box, xcss, Text, Inline } from '@atlaskit/primitives';
import CheckIcon from '@atlaskit/icon/glyph/check';
import { token } from '@atlaskit/tokens';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useSessionData } from '@confluence/session-data';
import { GradientCTAButton } from '@confluence/change-edition/entry-points/GradientCTAButton';
import { ConfluenceEdition } from '@confluence/change-edition/entry-points/ConfluenceEdition';
import {
	confluenceLocalStorageInstance as localStorage,
	PERSISTED_KEYS_ON_SERVER,
} from '@confluence/storage-manager';

import { useUpgradeUrlQuery } from '../hooks/useUpgradeUrlQuery';
import { useExperimentEnrollment } from '../hooks/useExperimentEnrollment';

const i18n = defineMessages({
	QuickAISummaryUpsellAdminCTA: {
		id: 'experiment-quick-summary.admin.cta',
		defaultMessage: 'Try it now',
		description:
			'The text on the link/button that encourages an admin user to try the new Quick AI Summary feature',
	},
	QuickAISummaryUpsellNonAdminCTA: {
		id: 'experiment-quick-summary.nonadmin.cta',
		defaultMessage: 'Ask admin to try',
		description:
			'The text on the button that encourages non-admin users to ask their admin to try the Quick AI Summary feature',
	},
	QuickAISummaryUpsellNonAdminSentMessage: {
		id: 'experiment-quick-summary.nonadmin.message.sent',
		defaultMessage: 'Sent to admin',
		description:
			'The text shown after a non-admin has clicked the button to ask their admin to try the Quick AI Summary feature',
	},
});

const NonAdminButtonStyles = xcss({
	flexDirection: 'row',
	alignSelf: 'end',
});

const NonAdminSentStyle = xcss({
	height: '32px',
	display: 'flex',
	alignItems: 'center',
	flexDirection: 'row',
	alignSelf: 'end',
});

const AdminButtonStyles = xcss({
	flexDirection: 'row',
	alignSelf: 'end',
});

export const QuickAISummaryButton = ({ isSiteAdmin }: { isSiteAdmin: boolean }) => {
	const [isClicked, setIsClicked] = useState(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();
	const { edition } = useSessionData();
	const { loading, UpgradeUrl } = useUpgradeUrlQuery({ skip: !isSiteAdmin });
	const { cohort } = useExperimentEnrollment('confluence_quick_summary');

	useEffect(() => {
		const item = localStorage?.getItem(
			PERSISTED_KEYS_ON_SERVER.PERSISTED_QUICK_SUMMARY_REQUEST_SENT,
		);

		if (item === 'true') {
			setIsClicked(true);
		}
	}, [isClicked]);

	const adminOnClick = () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'link',
				actionSubjectId: 'quickSummaryTryPremium',
				source: 'quickSummaryUpsellScreen',
				attributes: {
					isSiteAdmin,
					currentEdition: edition,
					targetEdition: ConfluenceEdition.PREMIUM,
					cohort,
				},
			},
		}).fire();
	};

	const nonAdminOnClick = () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'askAdminToTry',
				source: 'quick-ai-summary',
				attributes: {
					isSiteAdmin,
					edition,
				},
			},
		}).fire();

		localStorage.setItem(PERSISTED_KEYS_ON_SERVER.PERSISTED_QUICK_SUMMARY_REQUEST_SENT, 'true');
		setIsClicked(true);
	};

	if (!isSiteAdmin) {
		if (isClicked) {
			return (
				<Inline xcss={NonAdminSentStyle} space="space.075">
					<CheckIcon primaryColor={token('color.icon.success')} label="success" size="medium" />
					<Text size="medium" color="color.text.accent.gray.bolder">
						{formatMessage(i18n.QuickAISummaryUpsellNonAdminSentMessage)}
					</Text>
				</Inline>
			);
		} else {
			return (
				<Box xcss={NonAdminButtonStyles}>
					<GradientCTAButton onClick={nonAdminOnClick}>
						{formatMessage(i18n.QuickAISummaryUpsellNonAdminCTA)}
					</GradientCTAButton>
				</Box>
			);
		}
	}

	if (!loading) {
		return (
			<Box xcss={AdminButtonStyles}>
				<GradientCTAButton href={UpgradeUrl} onClick={adminOnClick} target="_blank">
					{formatMessage(i18n.QuickAISummaryUpsellAdminCTA)}
				</GradientCTAButton>
			</Box>
		);
	}

	return null;
};
